import api from '@apiInstance';

export default {
  add (data) {
    return api.post('reports', data);
  },
  get (id) {
    return api.fetch('reports/' + id);
  },
  list (params) {
    return api.fetch('/reports', { params });
  },
  update (data) {
    return api.patch('reports', data);
  },
  delete (id) {
    return api.remove('reports', id);
  },

  viewResults (id) {
    return api.fetch(`reports/${id}/viewresults`);
  }
};
