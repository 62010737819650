<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="Reports"
              :isEditable="false"
              :canAdd="false"
              :data-source="dataSource"
              @on-delete="(v) => onDelete(v)"
            >
              <template #action-buttons>
                <DxButton
                  icon="textdocument"
                  hint="View"
                  :onClick="onViewDetail"
                />
              </template>

              <template #columns>
                <DxColumn data-field="rpt_title" caption="Title" />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import reportsDesignerApi from '@api/reports_designer';
import { queryParameters } from '@/schema';

export default {
  name: 'ReportsPage',
  components: {},
  data: () => ({
    isToggledPivotDisplay: false,
    dataSource: [],
  }),
  mounted() {
    this.loadReports();
  },
  watch: {
    isToggledPivotDisplay(value) {
      //
    },
  },
  methods: {
    loadReports() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      reportsDesignerApi
        .list(params)
        .then(({ data }) => {
          if (data != null) {
            this.dataSource = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    handleEvent(action, event) {
      this.$nextTick(() => {
        //
      });
    },

    onViewDetail(event) {
      const data = event && event.row && event.row.data ? event.row.data : null;
      const url = `/pages/admin/reports/view?id=${data.id}`;
      this.$router.push(url);
    },
  },
};
</script>

<style></style>
